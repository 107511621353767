import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  LanguageFactsWrapper,
  LanguageFactsInner,
  LanguageFactsList,
  LanguageFactsItem,
  ClientFactHeadingWrap,
  StyledTitle,
  ClientFactText,
  ClientFactTitleWrap,
} from "./style";
const Text = React.lazy(() => import('@ui/text'));
// const SectionTitle = React.lazy(() => import('@ui/section-title'));
const Image = React.lazy(() => import('@ui/image'));

export default function LanguageFacts({ data }) {
  return (
    typeof window !== 'undefined' && (
      <React.Suspense fallback={<div></div>}>
    <LanguageFactsWrapper>
      <LanguageFactsInner>
        <Container>
          <Row>
            <Col lg={12}>
              {/* <SectionTitle className="introText"
                subtitle={data.section_title.subtitle}
                title={data.section_title.title}
                description={data.section_title.description}
              /> */}
              <ClientFactHeadingWrap>
                <ClientFactTitleWrap>
                  {data.images[0]&& <Image
                        src={data.images[0]?.src}
                        alt={data.images[0]?.alt || "Image"}
                      />}
                    {data.section_title.title && 
                    <StyledTitle>
                      {data.section_title.title}
                    </StyledTitle>}
                </ClientFactTitleWrap>
                {data.section_title.description && 
                  <ClientFactText>
                    {data.section_title.description}
                </ClientFactText>}
              </ClientFactHeadingWrap>
            </Col>
          </Row>
          <Row>
            {/* <Col lg={5} className="d-flex align-items-center">
              <Image src={data.images[0].src} alt="automated" />
            </Col> */}
            <Col lg={7}>
              {/* <Text {...textStyle}>{data.section_title.description}</Text> */}
              <LanguageFactsList>
                {data.items.map((item, index) => (
                  <LanguageFactsItem key={index} dangerouslySetInnerHTML={{ __html: item.title }}></LanguageFactsItem>
                ))}
              </LanguageFactsList>
              {data?.texts?.map((texts, index) => (
                <Text key={index} dangerouslySetInnerHTML={{ __html: texts.content }}></Text>
              ))}
            </Col>
          </Row>
        </Container>
      </LanguageFactsInner>
    </LanguageFactsWrapper>
    </React.Suspense>
)
  );
}
LanguageFacts.propTypes = {
  headingStyle: PropTypes.object,
};

LanguageFacts.defaultProps = {
  textStyle: {
    fontWeight: 700,
  },
};
